import { Footer, MainNavigation } from 'components/Blocks';
import React from 'react';
import tw from 'twin.macro';
import { CookieBanner } from '../components/Base';
import { data } from '../data/mainNavigation.data';

const Main = tw.main`relative w-full mt-20 min-height[calc(100vh - 5rem)] flex flex-col`;

const DefaultPage: React.FC = ({
  children,
  loading,
  withFooter = true,
}: {
  children: React.ReactNode;
  loading?: boolean;
  withFooter?: boolean;
}) => {
  return (
    <>
      <Main id="main-content">
        <CookieBanner />
        <MainNavigation navigation={data.blocks[0]} />
        {children}
      </Main>
      {withFooter && <Footer />}
    </>
  );
};

export default DefaultPage;
