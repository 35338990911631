import React from 'react';
import { AccordionBlock } from './Blocks/AccordionBlock';
import { AziendeBlock } from './Blocks/AziendeBlock';
import { BotBlock } from './Blocks/BotBlock';
import { CommunityBlock } from './Blocks/CommunityBlock';
import { CommunityPreviewBlock } from './Blocks/CommunityPreviewBlock';
import { ContactFormInfoBlock } from './Blocks/ContactFormInfoBlock';
import { ContactsInfoBlock } from './Blocks/ContactsInfoBlock';
import { DescriptionBlock } from './Blocks/DescriptionBlock';
import { EbookBlock } from './Blocks/EbookBlock';
import { FaqBlock } from './Blocks/FaqBlock';
//import { EbooksGridBlock } from './Blocks/EbooksGridBlock';
import { HeaderBlock } from './Blocks/HeaderBlock';
import { HeroAziende } from './Blocks/HeroAziende';
import { HeroBlock } from './Blocks/HeroBlock';
// import blocks components
import { HeroBlockHome } from './Blocks/HeroBlockHome';
import { ImageHighlightsBlock } from './Blocks/ImageHighlightsBlock';
import { ImageParagraphBlock } from './Blocks/ImageParagraphBlock';
import { ImageParagraphBlockAziende } from './Blocks/ImageParagraphBlockAziende';
import { MapBlock } from './Blocks/MapBlock';
import { PrivacyText } from './Blocks/PrivacyText';
import { SearchBlock } from './Blocks/SearchBlock';
import { SearchBlockHome } from './Blocks/SearchBlockHome';
import { ServiziBlock } from './Blocks/ServiziBlock';
import { StatsGridBlock } from './Blocks/StatsGridBlock';
import { TabsBlock } from './Blocks/TabsBlock';
import { TestimonialsBlock } from './Blocks/TestimonialsBlock';
import { TextBlock } from './Blocks/TextBlock';
import { ValuesGridBlock } from './Blocks/ValuesGridBlock';

const components = {
  'hero-block-home': HeroBlockHome,
  'hero-block': HeroBlock,
  'image-highlights-block': ImageHighlightsBlock,
  'image-paragraph-block': ImageParagraphBlock,
  'image-paragraph-block-aziende': ImageParagraphBlockAziende,
  'image-pargraph-block': ImageParagraphBlock,
  'values-grid-block': ValuesGridBlock,
  'contact-form-info-block': ContactFormInfoBlock,
  'contacts-info-block': ContactsInfoBlock,
  'numeri-block': StatsGridBlock,
  'carosello-storie-block': TestimonialsBlock,
  'accordion-block': AccordionBlock,
  'search-block': SearchBlock,
  'search-block-home': SearchBlockHome,
  'header-block': HeaderBlock,
  'tabs-block': TabsBlock,
  'community-block': CommunityBlock,
  'map-block': MapBlock,
  'description-block': DescriptionBlock,
  'bot-block': BotBlock,
  'community-preview-block': CommunityPreviewBlock,
  'privacy-text': PrivacyText,
  'servizi-block': ServiziBlock,
  'ebook-block': EbookBlock,
  'hero-aziende': HeroAziende,
  'aziende-block': AziendeBlock,
  'faq-block': FaqBlock,
  'text-block': TextBlock,
};

const isEmpty = (obj) => {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

const BlocksParser = ({ blocks, ...props }) => {
  if (!blocks) return null;

  const filteredComponents = blocks.filter(
    (component) => component.shortname !== null,
  );

  if (filteredComponents && filteredComponents.length > 0) {
    const pageComponents = filteredComponents.map((component, index) => {
      if (isEmpty(component)) return null;
      if (!component) return null;

      const Component = components[component.shortname];

      if (!Component) return null;

      return (
        <Component
          index={index}
          key={`component-${index}`}
          {...props}
          {...component}
        />
      );
    });

    if (pageComponents) {
      return pageComponents;
    }
  }

  return null;
};

export default BlocksParser;
